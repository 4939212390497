
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import { useIonRouter } from "@ionic/vue";
import { StatusBar } from "@capacitor/status-bar";
import { createAnimation } from "@ionic/vue";
import { NavigationBar } from "@ionic-native/navigation-bar";
export default defineComponent({
  name: "IntroPage",
  components: {
    IonPage,
    IonContent,
  },
  setup() {
    const router = useIonRouter();
    const myAnimation = (baseEl, opts) => {
      const { enteringEl } = opts;

      return createAnimation("root-transition")
        .addElement(enteringEl)
        .duration(1000)
        .easing("ease-in-out")
        .fromTo("opacity", "0", "1");
    };

    setTimeout(() => {
      router.push(
        {
          name: "Game",
        },
        myAnimation
      );
    }, 7500);
    if (!window.Ionic.platforms.includes("electron")) {
      document.addEventListener(
        "deviceready",
        function () {
          NavigationBar.hideNavigationBar();
          StatusBar.hide();
        },
        false
      );
      document.addEventListener(
        "resume",
        function () {
          NavigationBar.hideNavigationBar();
          StatusBar.hide();
        },
        false
      );
    }
  },
});
