
import { defineComponent } from "vue";
// import { ref, watch } from "vue";

export default defineComponent({
  name: "timerDisplay",
  props: ["timer"],
  setup() {
    const formatTime = (time: number) => {
      let minutes = Math.floor(time / 60);
      let seconds = Math.floor(time % 60);

      const newminutes = minutes < 10 ? "0" + minutes : minutes;
      const newseconds = seconds < 10 ? "0" + seconds : seconds;

      return newminutes + ":" + newseconds;
    };
    return { formatTime };
  },
});
