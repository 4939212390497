
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  onBeforeUnmount,
} from "vue";
import specialMel from "@/assets/sound/special.mp3";

export default defineComponent({
  name: "enemyClass",
  emits: ["screenEnd", "enemyClicked"],
  props: ["spawnDelay", "stage", "special", "element", "muted"],
  setup(props, { emit }) {
    /* eslint-disable */
    let selfposition = reactive({ top: 0, left: 0 });
    const enemyElement = ref<HTMLElement | null>(null);
    let leftright = Math.round(Math.random());
    const maxTop =
      !window.Ionic.platforms.includes("electron") &&
      !window.Ionic.platforms.includes("desktop")
        ? window.innerHeight
        : window.innerHeight * 0.7;

    const maxLeft = props.stage.width || window.innerWidth;
    const delay = Math.floor(Math.random() * props.spawnDelay);

    const randomIntFromInterval = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const deviceCheck = (mobile, tablet, desktop) => {
      if (
        window.Ionic.platforms.includes("electron") &&
        window.Ionic.platforms.includes("desktop")
      ) {
        return desktop;
      }
      if (window.Ionic.platforms.includes("desktop")) {
        return desktop;
      }
      if (window.Ionic.platforms.includes("tablet")) {
        return tablet;
      }
      if (window.Ionic.platforms.includes("mobile")) {
        return mobile;
      }
    };

    const difficultyRange = {
      1: {
        size: deviceCheck(70, 90, 120),
        speed: 8000,
        points: 10,
        src: "assets/alien.svg",
        delay: Math.floor(Math.random() * 600),
      },
      2: {
        size: deviceCheck(60, 80, 100),
        speed: 9000,
        points: 20,
        src: "assets/alien2.svg",
        delay: Math.floor(Math.random() * 4000) + delay,
      },
      3: {
        size: deviceCheck(45, 60, 80),
        speed: 10000,
        points: 30,
        src: "assets/alien3.svg",
        delay: Math.floor(Math.random() * 7000) + delay,
      },
      4: {
        size: deviceCheck(20, 34, 50),
        speed: 12000,
        points: 40,
        src: "assets/alien4.svg",
        delay: Math.floor(Math.random() * 9000) + delay,
      },
      5: {
        size:
          !window.Ionic.platforms.includes("electron") &&
          !window.Ionic.platforms.includes("desktop")
            ? 100
            : 350,
        speed: 1500,
        points: 40,
        src: "assets/alien5.svg",
        delay: 500,
        standing: randomIntFromInterval(5000, 30000),
      },
    };

    const percantage = () => {
      const percent = Math.floor(Math.random() * 100);
      let result = 0;
      if (percent <= 10) {
        result = Math.floor(Math.random() * 1 + 1) as number;
      }
      if (percent > 10) {
        result = Math.floor(Math.random() * 2 + 1) as number;
      }
      if (percent > 60) {
        result = Math.floor(Math.random() * 3 + 1) as number;
      }
      if (percent > 80) {
        result = Math.floor(Math.random() * 4 + 1) as number;
      }
      return result;
    };

    const setDifficulty: number = percantage();
    // console.log(setDifficulty);

    let difficulty = difficultyRange[setDifficulty];

    if (props.special) {
      difficulty = difficultyRange[5];
      leftright = 0;
    }

    let spawnTimeout;
    let endTimeout;
    let specialTimeout;
    let sound;
    onMounted(() => {
      // console.log("mounted", props.element);

      const position = {
        top: Math.floor(
          Math.random() *
            (Math.floor(Math.random() * (maxTop - difficulty.size)) - 60 + 1) +
            60
        ),
        left: leftright ? 0 - difficulty.size : maxLeft,
      };
      selfposition.top = position.top;
      selfposition.left = position.left;
      let rotate = "0deg";
      if (setDifficulty == 4) {
        rotate = leftright ? "90deg" : "-90deg";
      }
      const addStopTime = props.special ? difficulty.standing : 0;
      let enemyMovement;
      if (!props.special) {
        enemyMovement = [
          {
            transform: leftright
              ? "translate(" +
                (0 - difficulty.size) +
                "px," +
                selfposition.top +
                "px) rotate(" +
                rotate +
                ")"
              : "translate(" +
                maxLeft +
                "px," +
                selfposition.top +
                "px) rotate(" +
                rotate +
                ")",
            opacity: 1,
          },
          {
            transform: !leftright
              ? "translate(" +
                (0 - difficulty.size) +
                "px," +
                selfposition.top +
                "px) rotate(" +
                rotate +
                ")"
              : "translate(" +
                maxLeft +
                "px," +
                selfposition.top +
                "px) rotate(" +
                rotate +
                ")",
            opacity: 1,
          },
        ];
      } else {
        // console.log("what");
        selfposition.top = window.innerHeight + difficulty.size;
        if (enemyElement) enemyElement!.value!.style.opacity = "1";
        selfposition.left = Math.floor(
          Math.random() *
            (Math.floor(Math.random() * (maxLeft - difficulty.size)) -
              100 +
              1) +
            100
        );
        spawnTimeout = setTimeout(() => {
          selfposition.top = window.innerHeight - difficulty.size;
          if (props.special) {
            sound = new Audio(specialMel);
            if (!props.muted) sound.play();
          }
        }, addStopTime);
      }

      const enemyTiming = {
        duration: difficulty.speed,
        easing: "linear",
        delay: difficulty.delay,
        itterationCount: 1,
        fillMode: "forwards",
      };

      const enemyelement = enemyElement.value;

      if (!props.special) {
        enemyelement?.animate(enemyMovement, enemyTiming);

        endTimeout = setTimeout(() => {
          emit("screenEnd", "true");
        }, difficulty.speed + difficulty.delay + addStopTime);
      } else {
        endTimeout = setTimeout(() => {
          selfposition.top = window.innerHeight;
          specialTimeout = setTimeout(() => {
            emit("screenEnd", "true");
          }, 500);
        }, difficulty.speed + difficulty.delay + addStopTime);
      }
    });
    onBeforeUnmount(() => {
      if (spawnTimeout) clearInterval(spawnTimeout);
      if (endTimeout) clearInterval(endTimeout);
      if (!props.muted && sound) {
        if (sound.paused) {
          sound = new Audio(specialMel);
        }
      }
    });
    return {
      selfposition,
      enemyElement,
      difficulty,
      leftright,
      // shooted,
    };
  },
});
