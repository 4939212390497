
import { IonContent, IonPage } from "@ionic/vue";
import { useScoreStore } from "@/store/score";
import { useGameStore } from "@/store/game";
import { defineComponent, reactive } from "vue";
import Cursor from "@/components/uix/Cursor.vue";
import Stage from "@/components/uix/Stage.vue";
import Timer from "@/components/uix/Timer.vue";
import Shots from "@/components/uix/Shots.vue";
import Score from "@/components/uix/Score.vue";
import ScoreBoard from "@/components/uix/ScoreBoard.vue";
import EnemyClass from "@/components/enemyClass.vue";
import PowerupClass from "@/components/powerupClass.vue";
import melodie from "@/assets/sound/melodie.mp3";
import pwew from "@/assets/sound/pwew.mp3";
import clickSound from "@/assets/sound/click.mp3";
import reloadSound from "@/assets/sound/reload.mp3";
import moonshot from "@/assets/sound/moonshot.mp3";
import moonsplode from "@/assets/sound/moonsplode.mp3";

import { StatusBar } from "@capacitor/status-bar";
import { NavigationBar } from "@ionic-native/navigation-bar";
import app from "../../package.json";
export default defineComponent({
  name: "GamePage",
  components: {
    IonContent,
    IonPage,
    EnemyClass,
    PowerupClass,
    Cursor,
    Timer,
    Shots,
    Score,
    ScoreBoard,
    Stage,
  },
  setup() {
    const isDesktop =
      !window.Ionic.platforms.includes("electron") &&
      window.Ionic.platforms.includes("desktop")
        ? true
        : false;

    if (!window.Ionic.platforms.includes("electron")) {
      document.addEventListener(
        "deviceready",
        function () {
          NavigationBar.hideNavigationBar();
          StatusBar.hide();
        },
        false
      );
      document.addEventListener(
        "resume",
        function () {
          NavigationBar.hideNavigationBar();
          StatusBar.hide();
        },
        false
      );
    }

    const cursorPos = reactive({ x: 0, y: 0 });
    // const enemiesobj = ref([]);
    const enemiesarr: any = reactive({
      obj: [],
    });

    const special = reactive({ obj: [true] });
    let game = reactive({
      shots: 6,
      reload: 6,
      enemies:
        !window.Ionic.platforms.includes("electron") ||
        window.Ionic.platforms.includes("desktop")
          ? 9
          : 6,
      special: 1,
      delay: 8000,
      play: false,
      timer: 0,
      duration: 90,
      imgPos: 0,
      points: 0,
      electron: false,
      isPaused: false,
      showScores: false,
      stage: { width: 0, height: 0, left: 0, leftSecondary: 0 },
      position: {},
      scrollSpeed: 1,
      scrollSteps: 3,
      moonLimit: 5,
      moonHits: 0,
      moonShot: -10,
      moonDestroy: -500,
      muted: false,
      timeUp: 13,
      totalShots: 0,
      totalHits: 0,
      totalPlaytime: 0,
    });
    // console.log(enemiesarr.obj);
    let moon;
    for (let index = 0; index < game.enemies; index++) {
      // eslint-disable-next-line
      enemiesarr.obj.push(true);
    }

    let playTimer;
    let leftVal;
    let rightVal;
    let mel;
    const scoreStore = useScoreStore();
    const gameStore = useGameStore();

    game.muted = gameStore.getGameSound;
    if (!game.muted) {
      mel = new Audio(melodie);
      if (mel !== undefined) {
        mel.loop = true;
        mel.volume = 0.2;
      }
    }

    if (process.env.IS_ELECTRON) {
      game.electron = true;
      if (!game.muted) mel.play();
    }

    const scrollEnabled =
      !window.Ionic.platforms.includes("electron") &&
      !window.Ionic.platforms.includes("desktop")
        ? false
        : true;

    const startTimer = () => {
      game.timer = game.duration;
      game.totalPlaytime = game.duration;
      playTimer = setInterval(function () {
        if (!game.isPaused) {
          var seconds = game.timer - 1;
          game.timer = seconds;
          if (game.timer <= 0) {
            clearInterval(playTimer);
            game.play = false;
            if (moon) moon.classList.remove("destroy");
            if (game.points != 0) {
              scoreStore.addScore(
                game.points,
                game.totalPlaytime,
                game.totalShots,
                game.totalHits
              );
            }
          }
        }
      }, 1000);
    };

    const ended = (enemy, element) => {
      if (enemy == "special_1") {
        special.obj[element] = !special.obj[element];
        // console.log("ended");
      } else {
        enemiesarr.obj[enemy] = !enemiesarr.obj[enemy];
      }
    };

    const setCursor = (event) => {
      cursorPos.x = event.x;
      cursorPos.y = event.y;
      game.position = { x: event.x, y: event.y };
    };

    const setBackground = (event, type) => {
      // console.log("over", event);

      if (type === "left") {
        // console.log("left", game.stage.left);
        leftVal = setInterval(() => {
          if (game.stage.left < 0) {
            game.stage.left = game.stage.left + game.scrollSteps;
          }
        }, game.scrollSpeed);
      }
      if (type === "right") {
        // console.log("right", game.stage.left, game.stage.width - window.innerWidth);
        rightVal = setInterval(() => {
          if (game.stage.left > -(game.stage.width - window.innerWidth)) {
            game.stage.left = game.stage.left - game.scrollSteps;
          }
        }, game.scrollSpeed);
      }
    };
    let moonshotTimeout;

    const stopBackground = (type) => {
      if (type === "left") {
        clearInterval(leftVal);
      }
      if (type === "right") {
        clearInterval(rightVal);
      }
    };
    let isReloading = false;
    const fire = (e, type) => {
      if (!game.muted && mel) {
        mel.play();
      }
      if (isReloading) return;

      let sound;
      if (game.play && game.shots != 0) {
        sound = new Audio(pwew);
        if (!game.muted) {
          sound.play();
        }
      } else if (game.play && !isReloading) {
        sound = new Audio(reloadSound);
        if (!game.muted) {
          sound.play();
        }
      } else if (!game.play) {
        sound = new Audio(clickSound);
        if (!game.muted) {
          sound.play();
        }
        return;
      }

      // game click shots
      if (game.shots != 0 && game.play) {
        game.shots--;
        game.totalShots++;
        let items;
        if (type == "touch") {
          let touchobj = e.changedTouches[0]; // erster Finger
          items = document.elementsFromPoint(
            touchobj.clientX,
            touchobj.clientY
          );
        } else {
          items = document.elementsFromPoint(e.pageX, e.pageY);
        }

        for (const key in items) {
          // console.log(items[key].classList);

          if (items[key].classList.contains("enemy")) {
            game.totalHits++;
            const item = items[key] as HTMLElement;
            // eslint-disable-next-line
            const itemkey = item.dataset!.key!; // eslint-disable-next-line
            item.children[0].classList.add("rotate");
            // eslint-disable-next-line
            game.points += parseInt(item.dataset!.points!);

            setTimeout(() => {
              if (itemkey === "special_1") {
                special.obj["1"] = !special.obj["1"];
              } else {
                enemiesarr.obj[itemkey] = !enemiesarr.obj[itemkey];
              }
            }, 250);
            return;
          }
          if (items[key].classList.contains("powerup")) {
            return;
          }
          if (items[key].classList.contains("moonshoot")) {
            const item = items[key] as HTMLElement;
            moon = item;

            clearTimeout(moonshotTimeout);
            // eslint-disable-next-line
            item.previousElementSibling!.classList.remove("shake");
            if (game.moonHits < game.moonLimit) {
              if (!game.muted) {
                const sound = new Audio(moonshot);
                sound.play();
              }
              ++game.moonHits;
              game.points += game.moonShot;
              // eslint-disable-next-line
              item.previousElementSibling!.classList.add("shake");
              moonshotTimeout = setTimeout(() => {
                // eslint-disable-next-line
                item.previousElementSibling!.classList.remove("shake");
              }, 200);
            } else if (game.moonHits == game.moonLimit) {
              if (!game.muted) {
                const sound = new Audio(moonsplode);
                sound.play();
              }
              ++game.moonHits;
              game.points += game.moonDestroy;
              // eslint-disable-next-line
              item.previousElementSibling!.classList.add("destroy");
              item.classList.add("destroy");
            }
            return;
          }
        }
      } else {
        isReloading = true;
        setTimeout(() => {
          isReloading = false;
          game.shots = game.reload;
        }, 500);
        e.preventDefault();
      }
    };
    if (
      !window.Ionic.platforms.includes("electron") &&
      !window.Ionic.platforms.includes("desktop")
    ) {
      document.body.addEventListener(
        "touchstart",
        (e) => {
          e.preventDefault();
          fire(e, "touch");
        },
        false
      );
    } else {
      document.body.addEventListener(
        "click",
        (e) => {
          e.preventDefault();

          fire(e, "click");
        },
        false
      );
    }

    document.body.ondragstart = () => {
      return false;
    };

    document.body.onkeyup = function (e) {
      if (e.code == "Space" || e.keyCode == 32) {
        if (!isReloading && game.play) {
          isReloading = true;
          const sound = new Audio(reloadSound);
          if (!game.muted) {
            sound.play();
          }

          setTimeout(() => {
            isReloading = false;
            game.shots = game.reload;
          }, 500);
        }
      }
      if (e.code == "Escape" || e.keyCode == 27) {
        if (game.play) pauseGame();
      }
    };
    const showScores = () => {
      game.showScores = !game.showScores;
    };
    const startGame = () => {
      startTimer();
      setTimeout(() => {
        game.play = !game.play;
      }, 10);
      game.shots = game.reload + 1;
      game.points = 0;
      game.moonHits = 0;
      game.totalShots = 0;
      game.totalHits = 0;
    };

    const pauseGame = () => {
      game.play = !game.play;
      game.isPaused = !game.isPaused;
    };

    const resumeGame = () => {
      game.play = !game.play;
      game.isPaused = !game.isPaused;
    };
    const muteSounds = () => {
      // console.log(gameStore);
      if (game.muted) {
        gameStore.setGameSound(false);
        if (mel) {
          mel.muted = false;
          mel.play();
        } else {
          mel = new Audio(melodie);
          mel.muted = false;
          mel.play();
        }
      } else {
        gameStore.setGameSound(true);
        if (mel) {
          mel.muted = true;
          mel.pause();
        }
      }
      game.muted = !game.muted;
    };

    const closeApp = () => {
      // if (game.isPaused && game.points > 0) {
      //   scoreStore.addScore(game.points, game.timer, game.totalShots);
      // }
      window.ipc.send("close", "");
    };
    const dtClick = () => {
      if (
        !window.Ionic.platforms.includes("electron") &&
        window.Ionic.platforms.includes("desktop")
      ) {
        window.open("https://dead-technologies.de", "_blank");
      }
    };
    const downApp = (url) => {
      const setUrl = url
        ? url
        : "http://earthdefender.dead-technologies.de/Earth Defender Setup " +
          app.version +
          ".exe.zip";
      if (
        !window.Ionic.platforms.includes("electron") &&
        window.Ionic.platforms.includes("desktop")
      ) {
        window.open(setUrl, "_blank");
      }
    };
    const downAppGoogle = () => {
      if (
        !window.Ionic.platforms.includes("electron") &&
        window.Ionic.platforms.includes("desktop")
      ) {
        window.open(
          "https://play.google.com/store/apps/details?id=dt.earthDefender.app",
          "_blank"
        );
      }
    };

    const powerUp = (type) => {
      if (type == "timeUp") {
        game.timer = game.timer + game.timeUp;
        game.totalPlaytime = game.totalPlaytime + game.timeUp;
      }
    };

    return {
      setBackground,
      stopBackground,
      onclick,
      setCursor,
      ended,
      cursorPos,
      enemiesarr,
      special,
      game,
      startGame,
      resumeGame,
      closeApp,
      scoreStore,
      showScores,
      scrollEnabled,
      dtClick,
      isDesktop,
      downApp,
      app,
      muteSounds,
      downAppGoogle,
      powerUp,
    };
  },
});
