import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("img", {
      class: "cursor",
      src: "assets/cursor.svg",
      alt: "",
      width: "32",
      style: _normalizeStyle([
        { left: _ctx.cursorPosition.x - 16 + 'px' },
        { top: _ctx.cursorPosition.y - 16 + 'px' },
      ])
    }, null, 4)
  ]))
}