import { defineStore } from "pinia";

export const useGameStore = defineStore("game", {
  state: () => {
    return {
      GameSound: localStorage.getItem("GameSoundMuted")
        ? JSON.parse(localStorage.getItem("GameSoundMuted") || "")
        : false,
    };
  },
  getters: {
    getGameSound(state) {
      return state.GameSound;
    },
  },
  actions: {
    setGameSound(result) {
      this.GameSound = result;
      localStorage.setItem("GameSoundMuted", JSON.stringify(this.GameSound));
    },
  },
});
