import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { createPinia } from "pinia";
const pinia = createPinia();

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
// import { createAnimation } from "@ionic/vue";
/* Theme variables */
import "./theme/variables.css";

// const myAnimation = () => {
//   const backdropAnimation = createAnimation().fromTo(
//     "opacity",
//     "0.01",
//     "var(--backdrop-opacity)"
//   );

//   const wrapperAnimation = createAnimation().keyframes([
//     { offset: 0, opacity: "0", transform: "scale(0)" },
//     { offset: 1, opacity: "0.99", transform: "scale(1)" },
//   ]);

//   return createAnimation()
//     .easing("ease-out")
//     .duration(500)
//     .addAnimation([backdropAnimation, wrapperAnimation]);
// };

// , {
//   animated: true,
//   navAnimation: myAnimation,
// }
const app = createApp(App)
  .use(IonicVue, {
    platform: {
      desktop: (win) => {
        const isMobile =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            win.navigator.userAgent
          );
        return !isMobile;
      },
    },
  })
  .use(router)
  .use(pinia);

router.isReady().then(() => {
  app.mount("#app");
});
