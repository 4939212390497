
import { reactive, onMounted, onBeforeUnmount } from "vue";
import crystal from "@/assets/sound/crystal.mp3";
export default {
  emits: ["puShot"],
  props: ["stage", "muted"],
  setup(props, { emit }) {
    const setPosition = reactive({ top: "0px", left: "0px" });
    const element = reactive({ shown: false });
    let elementStartTimeout;
    let elementTimeout;
    const randomIntFromInterval = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1) + min);
    };

    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    const initElement = () => {
      elementStartTimeout = setTimeout(() => {
        setElement();
        element.shown = true;
        if (!props.muted) {
          const sound = new Audio(crystal);
          sound.play();
        }
      }, randomIntFromInterval(7500, 35000));
    };
    const setElement = () => {
      const windowHeight = props.stage.height;
      const windowWidth = props.stage.width;
      const borderProtect =
        window.Ionic.platforms.includes("electron") &&
        window.Ionic.platforms.includes("desktop")
          ? 250
          : 20;
      setPosition.top =
        getRandomInt(borderProtect, windowHeight - borderProtect) + "px";
      setPosition.left =
        getRandomInt(borderProtect, windowWidth - borderProtect) + "px";
      elementTimeout = setTimeout(
        () => {
          element.shown = false;
          initElement();
        },
        window.Ionic.platforms.includes("electron") &&
          window.Ionic.platforms.includes("desktop")
          ? 6000
          : 4000
      );
    };
    const shotPowerup = () => {
      emit("puShot", "timeUp");
      clearTimeout(elementTimeout);
      element.shown = false;
      initElement();
    };

    const getWidth = () => {
      return !window.Ionic.platforms.includes("electron") &&
        !window.Ionic.platforms.includes("desktop")
        ? "24px"
        : "32px";
    };
    onMounted(() => {
      initElement();
    });

    onBeforeUnmount(() => {
      if (elementStartTimeout) clearInterval(elementStartTimeout);
      if (elementTimeout) clearInterval(elementTimeout);
    });

    return { setPosition, element, shotPowerup, getWidth };
  },
};
