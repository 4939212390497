
import { defineComponent, ref, reactive } from "vue";

export default defineComponent({
  name: "gameStage",
  props: ["game"],
  emits: ["update:stage", "update:position"],
  setup(_, { emit }) {
    const stage = ref<HTMLImageElement | null>(null);
    let imgPos = reactive({ left: 0 });

    const imgLoaded = () => {
      /* eslint-disable */
      // eslint-disable-next-line
      const ratio = window.innerHeight / 2160;
      // console.log(ratio);
      // console.log(stage.value);

      // eslint-disable-next-line
      imgPos.left = (window.innerWidth - stage.value!.width) / 2;
      if (
        !window.Ionic.platforms.includes("electron") &&
        !window.Ionic.platforms.includes("desktop")
      ) {
        // console.log(window.innerWidth);

        emit("update:stage", {
          width: window.innerWidth + 200 || 0,
          height: window.innerHeight || 0,
          left: 0,
          leftSecondary: 0,
          ratio: ratio,
        });
        emit("update:position", {
          x: 0 || 0,
        });
      } else {
        emit("update:stage", {
          width: stage.value!.width || 0,
          height: stage.value!.height || 0,
          left: imgPos.left,
          leftSecondary: imgPos.left,
          ratio: ratio,
        });
        emit("update:position", {
          x: imgPos.left || 0,
        });
      }
    };

    const moonshot = () => {
      console.log("moonshot");
    };

    return { stage, imgLoaded, imgPos, moonshot };
  },
});
