import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import GamePage from "../views/GamePage.vue";
import IntroPage from "../views/IntroPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/intro",
  },
  {
    path: "/game",
    name: "Game",
    component: GamePage,
  },
  {
    path: "/intro",
    name: "Intro",
    component: IntroPage,
  },
];

const router = createRouter({
  history: process.env.IS_ELECTRON
    ? createWebHashHistory()
    : createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
