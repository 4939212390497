
import { defineComponent } from "vue";
// import { ref, watch } from "vue";
import { useScoreStore } from "@/store/score";

export default defineComponent({
  name: "scoreDisplay",
  props: ["score"],
  emits: ["close"],
  setup(_, { emit }) {
    const scoreStore = useScoreStore();
    const formatTime = (time: number) => {
      let minutes = Math.floor(time / 60);
      let seconds = Math.floor(time % 60);

      const newminutes = minutes < 10 ? "0" + minutes : minutes;
      const newseconds = seconds < 10 ? "0" + seconds : seconds;

      return newminutes + ":" + newseconds;
    };
    const close = () => {
      emit("close", "true");
    };
    return { scoreStore, close, formatTime };
  },
});
