import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "shots" }
const _hoisted_2 = {
  key: 0,
  class: "reload"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shots, (shot) => {
      return (_openBlock(), _createElementBlock("div", {
        key: shot,
        class: "shot"
      }))
    }), 128)),
    (_ctx.shots == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Reload"))
      : _createCommentVNode("", true)
  ]))
}