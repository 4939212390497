import { defineStore } from "pinia";

export const useScoreStore = defineStore("score", {
  state: () => {
    return {
      score: localStorage.getItem("scores")
        ? JSON.parse(localStorage.getItem("scores") || "")
        : ([] as any[]),
    };
  },
  getters: {
    getScore(state) {
      // let localStorageScore = [];
      // if(localStorage.getItem('scores')){
      //    localStorageScore = JSON.parse(localStorage.getItem('scores') || '');
      // }
      const sortet = JSON.parse(JSON.stringify(state.score));
      return sortet
        .sort((a, b) =>
          a.result > b.result ? 1 : b.result > a.result ? -1 : 0
        )
        .reverse();
    },
  },
  actions: {
    addScore(
      result: number,
      playtime: number,
      totalShots: number,
      totalHits: number
    ) {
      this.score.push({ result, playtime, totalShots, totalHits });
      // console.log(this.score);

      localStorage.setItem("scores", JSON.stringify(this.score));
    },
  },
});
